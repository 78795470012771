import {type TrainerType} from '@englex/trainer';

import {dictionaryListPath, teacherDictionaryListPath} from 'common/paths';
import {type DictionaryOwnerRole} from 'components/Dictionary/shared/contexts';

export type DictionaryListUrlParams = {
  studentId: string;
  listId: string;
};

export const trainerPathCreator = (
  studentId: number | string,
  listId: number | string,
  dictionaryOwnerRole: DictionaryOwnerRole
) =>
  dictionaryOwnerRole === 'student'
    ? `${dictionaryListPath(studentId, listId)}/train`
    : `${teacherDictionaryListPath(studentId, listId)}/train`;

export const trainerTypePathCreator = (
  studentId: number | string,
  listId: number | string,
  dictionaryOwnerRole: DictionaryOwnerRole,
  type: TrainerType
) => `${trainerPathCreator(studentId, listId, dictionaryOwnerRole)}/${type}`;

export const generateKey = () => new Date().getTime().toString();
