import React from 'react';

import {DragHandle} from './DragHandle';

export const FlexHandle: React.FC<{disabled?: boolean}> = React.memo(({disabled = false}) => (
  <span className="flex-handle-row">
    <span className="flex-handle-col">
      {Array.from(Array(30).keys()).map((_, i) => (
        <DragHandle key={i} disabled={disabled} />
      ))}
    </span>
  </span>
));
