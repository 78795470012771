import React, {PureComponent} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {Portal} from 'react-portal';
import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import {type PoolPortalPropsWithScrolls, type ScrollProps} from './interface';

const maxPortalPoolHeight = 200;

interface State {
  shouldPortal: boolean;
  poolHeight: number;
}

class Calculator extends PureComponent<PoolPortalPropsWithScrolls, State> {
  public state: State = {
    shouldPortal: false,
    poolHeight: 0
  };
  private portal?: HTMLElement | null;

  public componentDidMount(): void {
    this.portal = document.getElementById(this.props.containerId);
    this.setupPortalDecision();
  }

  public componentDidUpdate(
    prevProps: Readonly<PoolPortalPropsWithScrolls>,
    prevState: Readonly<State>
  ): void {
    if (
      this.props.scrollTop !== prevProps.scrollTop ||
      this.state.poolHeight !== prevState.poolHeight
    ) {
      this.setupPortalDecision();
    }
  }

  public render() {
    const {placeholder, portalWrapperClassName, children} = this.props;
    const {shouldPortal} = this.state;
    return (
      <div className="x-gap-drag-pool-wrapper">
        <ReactResizeDetector onResize={this.onPoolResize} refreshRate={16} refreshMode="throttle">
          {shouldPortal && placeholder ? placeholder : children(shouldPortal)}
        </ReactResizeDetector>
        {shouldPortal && (
          <Portal node={this.portal}>
            <div className={portalWrapperClassName}>{children()}</div>
          </Portal>
        )}
      </div>
    );
  }

  private setupPortalDecision = () => {
    const {extraHeight, playerTop, getParent} = this.props;
    const {poolHeight, shouldPortal} = this.state;
    const parent = getParent();
    if (playerTop !== undefined && parent && this.portal) {
      const {top: parentTop, bottom: parentBottom} = parent.getBoundingClientRect();

      const adjustedPlayerTop =
        playerTop - (poolHeight > maxPortalPoolHeight ? poolHeight - maxPortalPoolHeight : 0);
      const adjustedPlayerBottom =
        playerTop + Math.min(poolHeight, maxPortalPoolHeight) + extraHeight;

      const parentIsSemiVisible =
        parentTop < adjustedPlayerTop && parentBottom > adjustedPlayerBottom;
      const parentIsFullyVisibleOrInvisible =
        parentTop > adjustedPlayerTop || parentBottom < adjustedPlayerBottom;

      if (!shouldPortal && parentIsSemiVisible) {
        this.setState({shouldPortal: true});
      } else if (shouldPortal && parentIsFullyVisibleOrInvisible) {
        this.setState({shouldPortal: false});
      }
    }
  };

  private onPoolResize = (width: number, height: number) => {
    if (this.props.childMayUnmount) {
      return height && this.setState({poolHeight: height});
    }
    this.setState({poolHeight: height});
  };
}

const mapStateToProps = (state: AppState): ScrollProps => {
  let [playerTop, scrollTop]: Array<number | undefined> = [undefined, undefined];
  if (state.xplayer?.layout) {
    [playerTop, scrollTop] = [state.xplayer.layout.playerTop, state.xplayer.layout.scrollTop];
  }
  return {playerTop, scrollTop};
};

const ConnectedCalculator = connect(mapStateToProps)(Calculator);

export {Calculator, ConnectedCalculator};
