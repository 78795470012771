import React, {type FC} from 'react';
import {type ConnectDragSource} from 'react-dnd';
import capitalize from 'react-bootstrap/lib/utils/capitalize';
import classNames from 'classnames';

import DragHandle from 'components/DragHandle/DragHandle';

import {FlexHandle} from '../../DragHandle/FlexHandle';
import {type PointerListenerProps} from '../../Pointer/element/PointerElementListener';

interface Props extends PointerListenerProps {
  drag?: ConnectDragSource;
  id?: string;
  answer: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  startOfSentence?: true;
  onSelect?(e: React.MouseEvent<HTMLDivElement>): void;
  flexHandle?: true;
  useSVGHack?: true;
}

const DragGap: FC<Props> = ({
  drag,
  id,
  answer,
  className = 'x-gap-drag-preview',
  style,
  disabled,
  onSelect,
  startOfSentence,
  flexHandle,
  children,
  useSVGHack,
  ...props
}) => {
  return (
    <div
      ref={drag}
      id={id}
      className={classNames(className, {flexHandle})}
      style={style}
      onClick={onSelect ? onSelect : undefined}
      {...props}
    >
      {flexHandle ? (
        <FlexHandle disabled={disabled} />
      ) : (
        <DragHandle disabled={disabled} useSVGHack={useSVGHack} />
      )}
      {children || (
        <span className="value" title={answer}>
          {!startOfSentence ? answer : capitalize(answer)}
        </span>
      )}
    </div>
  );
};

export default DragGap;
