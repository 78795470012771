import React, {PureComponent} from 'react';

import {type Defaults, type PoolPortalProps} from './interface';
import {Calculator, ConnectedCalculator} from './Calculator';
import './PoolPortalWrapper.scss';

class PoolPortalWrapper extends PureComponent<PoolPortalProps> {
  public static defaultProps: Defaults = {
    containerId: 'x-player-pool-portal',
    extraHeight: 13,
    validRoles: ['student']
  };
  private get validRole() {
    const {role, validRoles} = this.props;
    return role ? validRoles.includes(role) : true;
  }

  public render() {
    const {children, closed, scrollFromOwnProps} = this.props;
    return !closed && this.validRole ? (
      scrollFromOwnProps ? (
        <Calculator {...this.props}>{children}</Calculator>
      ) : (
        <ConnectedCalculator {...this.props}>{children}</ConnectedCalculator>
      )
    ) : (
      <div className="x-gap-drag-pool-wrapper">{children()}</div>
    );
  }
}

export {PoolPortalWrapper};
